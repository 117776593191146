@media(min-width: 576px) {
    .btn-select-role { width: 90%; }
}

@media(min-width: 768px) {
    .btn-select-role { width: 85%; }
}

.app-logo { width: 85%; }
@media(min-width: 576px) {
    .app-logo { width: 80%; }
}

@media(min-width: 768px) {
    .app-logo { width: 70%; }
    .h-100-desktop { height: 100vh; }
}

/* LOGO SWARA */
.app-logo-swara { width: 85%; }
@media(min-width: 576px) {
    .app-logo-swara { width: 80%; }
}
@media(min-width: 768px) {
    .app-logo-swara { width: 70%; }
    .h-100-desktop { height: 100vh; }
}

@media(min-width: 576px) and (max-width: 768px) {
    .controller-auth {
        width: 85%;
        margin: auto;
    }
}

@media(min-width: 992px) {
    .controller-auth {
        width: 90%;
        margin: auto;
    }
}

@media(min-width: 1300px) {
    .controller-auth { 
        width: 75%;
        margin: auto;
    }
}

.mb-6 { margin-bottom: 4rem!important; }
.text-muted-cstm { color: #bebebe!important; }

#logo-kdigital {
    width: 400px;
    position: fixed;
    left: -20px;
    top: -140px;
}

 .card-width {
    width: 150px;
} 

 @media(min-width: 332px) { 
    .card-width { 
        width: auto;
    } 
}

@media(max-width: 375px) { 
    .card-row { width: auto; } 
} 

 /* @media(min-width: 320px) { 
    .card-width { 
        width: 134px;
        height: 30px;
        border-radius: 7px;
        background: #E6E2E2;
    } 
    .text { margin-top: 6px; }
    .card-row { width: auto; }
} */