#bg-siluet {
    display: inline-block!important;
    height: 300px;
    position: fixed;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: .5;
}

.btn.disabled, .fc button.disabled, .ajax-upload-dragdrop .disabled.ajax-file-upload, .swal2-modal .swal2-buttonswrapper .disabled.swal2-styled, .wizard > .actions a.disabled, .btn:disabled, .fc button:disabled, .ajax-upload-dragdrop .ajax-file-upload:disabled, .swal2-modal .swal2-buttonswrapper .swal2-styled:disabled, .wizard > .actions a:disabled {
    opacity: 1!important;
}


.text-bold {
    font-weight: bold;
}

#logo-kdigital {
    width: 300px;
    position: fixed;
    left: -20px;
    top: -100px;
}

/* MOBILE */
.size-mobile {
    display: none!important;
}

#logo-kdigital-2 {
    width: 95%;
    position: fixed;
    left: -3px;
    top:  -1px;
    background-color:#0F0F0F;
    z-index: 99;
}

#app-logo-swara {
    width: 150px;
    position: fixed;
    left: 40px;
    top: 10px;
    z-index: 1;
}

.bg-header { 
    background-color: #d3d3d3;
    margin-bottom: 0px;
}

.photo-profile-group {
    height: 150px;
    border-radius: 100%;
    position: relative;
    width: fit-content;
    margin: auto;
}

.photo-profile {
    height: inherit;
    border-radius: inherit;
}

/* Circle Status */
.status-circle{
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid white;
}

/* Meeting Room Baru */
.range-wrap {
    position: relative;
    margin: 0 auto 3rem;
}

.range {
    width: auto;
}

@media(max-width: 760px) {
    .form-control-file, .form-control-range {
        width: 250px!important;
    }
}

.bubble {
    background: rgb(0, 0, 0);
    color: white;
    padding: 4px 12px;
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
}

.bubble::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 2px;
    background: rgb(255, 255, 255);
    top: -1px;
    left: 50%;
}